.area-card2 {
  padding-left: 0.104167rem;
  padding-right: 0.104167rem;
  padding-top: 0.052083rem;
  padding-bottom: 0.052083rem;
  height: 0.265625rem;
  background-color: #ffffff;
  border-radius: 0.026042rem;
  box-shadow: 0 0.026042rem 0.026042rem #ddd;
}

.gray-title {
  font-size: 0.078125rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #b5b5b6;
}

.pointer {
  cursor: pointer;
}

.icon2 {
  width: 0.125rem;
}

.area-top {
  margin-left: 0.104167rem;
  width: 100%;
  font-size: 0.09375rem;
  color: #595757;
  margin-top: 0.104167rem;
  margin-bottom: 0.078125rem;
}