.news-list-container .list-container {
  padding: 0.322917rem 1.734375rem 0.385417rem;
  border-top: 0.005208rem solid #e6e6e6;
}

.news-list-container .list-container .indexs {
  font-size: 0.078125rem;
  line-height: 0.078125rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #9AA2AA;
}

.news-list-container .list-container .indexs a,
.news-list-container .list-container .indexs span {
  display: inline-block;
  margin: 0.026042rem;
  color: #9AA2AA;
  text-decoration: none;
}

.news-list-container .list-container .list {
  margin-top: 0.276042rem;
  border: 0.005208rem solid #E6E6E6;
  border-radius: 0.052083rem;
  overflow: hidden;
}

.news-list-container .list-container .list .header {
  background: #FAFAFA;
  height: 0.546875rem;
  font-size: 0.15625rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #3E3A39;
  line-height: 0.546875rem;
  padding: 0 0.255208rem;
  box-sizing: border-box;
}

.news-list-container .list-container .list .news {
  height: 0.317708rem;
  line-height: 0.317708rem;
  padding: 0 0.255208rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 0.078125rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #595757;
  cursor: pointer;
}

.news-list-container .list-container .list .news:nth-child(2n+1) {
  background: #FAFAFA;
}

.news-list-container .list-container .list .news .game-icon {
  width: 0.15625rem;
  height: 0.114583rem;
}

.news-list-container .list-container .list .news .notice-icon {
  width: 0.125rem;
  height: 0.104167rem;
}

.news-list-container .list-container .list .news .type {
  color: #EEC31C;
}

.news-list-container .list-container .list .news .title {
  margin-left: 0.0625rem;
  max-width: 4.166667rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-list-container .list-container .list .news .top {
  height: 0.104167rem;
  line-height: 0.104167rem;
  padding: 0 0.072917rem;
  background: #EEC31C;
  font-size: 0.072917rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #3E3A39;
  border-radius: 0.026042rem;
  margin-left: 0.072917rem;
}

.news-list-container .list-container .list .news .time {
  margin-left: auto;
}

.news-list-container .list-container .list .footer {
  background: #E6E6E6;
  height: 0.546875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-list-container .list-container .list .footer .more-btn {
  cursor: pointer;
  display: block;
  border: none;
  padding: 0 0.104167rem;
  height: 0.229167rem;
  text-align: center;
  line-height: 0.229167rem;
  font-size: 0.09375rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #fafafa;
  background: #EEC31C;
  border-radius: 0.026042rem;
}