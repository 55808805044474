.footer-container2 {
  height: 1.359375rem;
  background-color: #000;
  text-align: center;
  padding-top: 0.260417rem;
}

.footer-container2 .logo {
  width: 1.015625rem;
  height: 0.291667rem;
}

.footer-container2 .contact2 {
  font-size: 0.078125rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #b5b5b6;
  line-height: 0.125rem;
  margin-top: 0.244792rem;
}

.footer-container2 .flexbox2 {
  margin-top: 0.088542rem;
  font-size: 0.0625rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #b5b5b6;
  line-height: 0.125rem;
  text-align: center;
}

.footer-container2 .info2 {
  margin-top: 0.088542rem;
  font-size: 0.0625rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #b5b5b6;
  line-height: 0.125rem;
  text-align: center;
}

.footer-container2 .info2 i {
  width: 0.083333rem;
  height: 0.083333rem;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: sub;
  margin: 0 0.010417rem;
}

.footer-container2 .info2 .icon_police {
  background-image: url("https://beian.mps.gov.cn/img/logo01.dd7ff50e.png");
}