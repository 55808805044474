.page {
  display: flex;
  display: -webkit-flex;
  background-color: #f6f6f6;
  padding: 0.322917rem 1.734375rem 0.385417rem;
  color: #f6f6f6;
}

.flexbox {
  width: 1.302083rem;
  display: flex;
  display: -webkit-flex;
}

.player-section {
  width: 100%;
  background-color: #fff;
}

.player-section .card {
  height: 0.546875rem;
  box-shadow: 0 0.005208rem 0.015625rem #eee;
  padding-top: 0.161458rem;
  padding-left: 0.135417rem;
  padding-right: 0.135417rem;
  padding-bottom: 0.161458rem;
}

.player-section .card .title {
  color: #595757;
  font-size: 0.09375rem;
}

.pointer {
  cursor: pointer;
}

.player-list-section {
  width: 100%;
  background-color: #fff;
  margin-top: 0.15625rem;
}

.player-list-section .title {
  font-size: 0.09375rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #595757;
}

.video-section {
  width: 100%;
  background-color: #fff;
}

.video-section .title {
  color: #3E3A39;
}

.video-section .card {
  margin-top: 0.078125rem;
}

.video-section .card img {
  width: 100%;
}

.gray-title {
  font-size: 0.078125rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #b5b5b6;
}

.title {
  font-size: 0.078125rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #3E3A39;
}