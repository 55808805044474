@font-face {
  font-family: Bebas;
  src: url("../../font/BEBAS.ttf");
}

@font-face {
  font-family: Noto;
  src: url("../../font/noto-sans.woff.ttf");
}

body,
ul {
  margin: 0;
  padding: 0;
}

html {
  min-width: 5.208333rem;
}

html,
button,
input,
select,
textarea {
  font-family: "Hiragino Sans GB","Helvetica Neue",Helvetica,Tahoma,"Microsoft Yahei",sans-serif;
}

a {
  white-space: nowrap;
  display: block;
  text-align: center;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
}

#root {
  position: relative;
}

@media not screen and (min-width: 769px) and (max-width: 992px) {
  main {
    width: 4.166667rem;
  }

  .introductionPage .content {
    width: 4.166667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.052083rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  main {
    width: 4.791667rem;
  }

  .introductionPage .content {
    width: 4.791667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    width: 2.109375rem;
    height: 1.1875rem;
  }

  .video-title {
    max-width: 2.109375rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  main {
    width: 5.208333rem;
  }

  .introductionPage .content {
    width: 5.208333rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    height: 1.302083rem;
    width: 2.317708rem;
  }

  .video-title {
    max-width: 2.317708rem;
  }
}

@media only screen and (min-width: 1600px) {
  main {
    width: 6.25rem;
  }

  .introductionPage .content {
    width: 6.25rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.28125rem;
  }

  .video-poster {
    height: 1.567708rem;
    width: 2.78125rem;
  }

  .video-title {
    max-width: 2.78125rem;
  }
}

.highlights {
  height: 0.333333rem;
}

.video-title {
  font-family: Bebas, Roboto-Black,Roboto;
  color: #373739;
  box-sizing: border-box;
  padding-left: 0.052083rem;
  border-left: 0.03125rem solid #F8CA00;
  width: 100%;
  font-size: 0.166667rem;
  line-height: 0.166667rem;
  font-weight: 500;
  color: #333;
  margin-top: 0.078125rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
}

.video-list {
  margin-top: 0.53125rem;
}

.video-list::after {
  content: '';
  height: 0;
  display: block;
  clear: both;
}

.video-list .video-item {
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.video-list .video-item:nth-child(odd) {
  float: left;
}

.video-list .video-item:nth-child(even) {
  float: right;
}

.video-poster {
  position: relative;
  overflow: hidden;
}

.video-poster .play-icon {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  width: 0.40625rem;
  height: 0.40625rem;
  margin-left: -0.203125rem;
  margin-top: -0.203125rem;
}

.video-poster img {
  width: 100%;
}

.video-poster .mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1;
}

main {
  margin: 1.0625rem auto;
}

.homePage {
  background: url("../../public/img/bg.jpg") no-repeat;
  background-size: cover;
}