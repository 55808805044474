.home-container .swiper-pagination {
  bottom: 0.15625rem !important;
}

.home-container .swiper-pagination .swiper-pagination-bullet {
  width: 0.072917rem !important;
  height: 0.072917rem !important;
  opacity: 1 !important;
  margin: 0 0.052083rem;
  border: 0.005208rem solid #fff;
  background-color: rgba(0,0,0,0) !important;
}

.home-container .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.home-container .swiper-button-prev,
.home-container .swiper-button-next {
  color: #fff;
  transform: scale(0.9, 0.75);
  top: 50%;
  transform: translateY(-50%);
}

.home-container .swiper-button-prev {
  left: 0.260417rem;
}

.home-container .swiper-button-next {
  right: 0.260417rem;
}

.home-container .img-tab {
  height: 3.114583rem;
  overflow: hidden;
}

.home-container .img-tab img {
  width: 100%;
  height: auto;
}

.home-container .video-block {
  padding: 0.177083rem 0.984375rem;
  display: flex;
  justify-content: center;
}

.home-container .video-block .douyin {
  padding: 0.135417rem 0 0.135417rem 0.322917rem;
  display: flex;
  align-items: center;
  margin-right: 0.125rem;
  position: relative;
}

.home-container .video-block .douyin:hover .des {
  display: block !important;
}

.home-container .video-block .douyin .des {
  width: 2.489583rem;
  height: 0.958333rem;
  position: absolute;
  background: url("./img/douyin_des.png") no-repeat;
  background-size: 100% 100%;
  top: -0.854167rem;
  z-index: 2;
  display: none;
}

.home-container .video-block .douyin .logo {
  width: 0.359375rem;
  height: 0.583333rem;
  margin-right: 0.322917rem;
}

.home-container .video-block .douyin .text {
  font-size: 0.072917rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #595757;
  line-height: 0.15625rem;
}

.home-container .video-block .douyin .text:last-child {
  margin-left: 0.140625rem;
}

.home-container .video-block .kuaishou {
  padding: 0.135417rem 0 0.135417rem 0.322917rem;
  display: flex;
  align-items: center;
  position: relative;
}

.home-container .video-block .kuaishou:hover .des {
  display: block !important;
}

.home-container .video-block .kuaishou .des {
  width: 2.489583rem;
  height: 0.958333rem;
  position: absolute;
  background: url("./img/kuaishou_des.png") no-repeat;
  background-size: 100% 100%;
  top: -0.854167rem;
  z-index: 2;
  display: none;
}

.home-container .video-block .kuaishou .logo {
  width: 0.322917rem;
  height: 0.552083rem;
  margin-right: 0.338542rem;
}

.home-container .video-block .kuaishou .text {
  font-size: 0.072917rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #595757;
  line-height: 0.15625rem;
}

.home-container .video-block .kuaishou .text:last-child {
  margin-left: 0.140625rem;
}

.home-container .supports {
  padding: 0.104167rem 1.088542rem;
  position: relative;
  background: #fafafa;
}

.home-container .supports .swiper-container {
  overflow: visible !important;
}

.home-container .supports .support {
  width: 0.875rem;
  height: 0.734375rem;
  border-radius: 0.052083rem;
  cursor: pointer;
}

.home-container .supports .support img {
  width: 100%;
  height: 100%;
}

.home-container .supports .wx {
  position: relative;
}

.home-container .supports .wx:hover .bubble {
  display: block;
}

.home-container .supports .wx .bubble {
  display: none;
  width: 0.927083rem;
  height: 0.958333rem;
  padding: 0.052083rem 0.104167rem;
  box-sizing: border-box;
  background: url("./img/bubble.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  z-index: 2;
  top: -0.958333rem;
  left: 50%;
  transform: translateX(-50%);
}

.home-container .supports .wx .bubble .qr {
  width: 0.71875rem;
  height: 0.71875rem;
  background: url("./img/qrcode/wx.jpg") no-repeat;
  background-size: 100% 100%;
}

.home-container .supports .shipinhao {
  position: relative;
}

.home-container .supports .shipinhao:hover .bubble {
  display: block;
}

.home-container .supports .shipinhao .bubble {
  display: none;
  width: 0.927083rem;
  height: 0.958333rem;
  padding: 0.052083rem 0.104167rem;
  box-sizing: border-box;
  background: url("./img/bubble.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  z-index: 2;
  top: -0.958333rem;
  left: 50%;
  transform: translateX(-50%);
}

.home-container .supports .shipinhao .bubble .qr {
  width: 0.71875rem;
  height: 0.71875rem;
  background: url("./img/qrcode/shipinhao.jpg") no-repeat;
  background-size: 100% 100%;
}

.home-container .supports .dewu {
  position: relative;
}

.home-container .supports .dewu:hover .bubble {
  display: block;
}

.home-container .supports .dewu .bubble {
  display: none;
  width: 0.927083rem;
  height: 0.958333rem;
  padding: 0.052083rem 0.104167rem;
  box-sizing: border-box;
  background: url("./img/bubble.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  z-index: 2;
  top: -0.958333rem;
  left: 50%;
  transform: translateX(-50%);
}

.home-container .supports .dewu .bubble .qr {
  width: 0.71875rem;
  height: 0.71875rem;
  background: url("./img/qrcode/dewu.jpg") no-repeat;
  background-size: 100% 100%;
}

.home-container .supports .uc {
  position: relative;
}

.home-container .supports .uc:hover .bubble {
  display: block;
}

.home-container .supports .uc .bubble {
  display: none;
  width: 0.927083rem;
  height: 0.958333rem;
  padding: 0.052083rem 0.104167rem;
  box-sizing: border-box;
  background: url("./img/bubble.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  z-index: 2;
  top: -0.958333rem;
  left: 50%;
  transform: translateX(-50%);
}

.home-container .supports .uc .bubble .qr {
  width: 0.71875rem;
  height: 0.71875rem;
  background: url("https://w4.lurenwang.com/upload/icon/9a966abcf0604d7e99bace25a8c8d8d5.png") no-repeat;
  background-size: 100% 100%;
}

.home-container .supports .prev-btn,
.home-container .supports .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  color: #fff;
  width: 0.114583rem;
  height: 0.114583rem;
  transform: scale(1, 1);
  z-index: 9;
}

.home-container .supports .prev-btn {
  left: 0.78125rem;
  background: url("./img/left.png");
  background-size: 100%;
}

.home-container .supports .prev-btn::after {
  content: '';
}

.home-container .supports .next-btn {
  right: 0.78125rem;
  background: url("./img/right.png");
  background-size: 100%;
}

.home-container .supports .next-btn::after {
  content: '';
}

.home-container .board {
  padding: 0.307292rem 0 0.286458rem 0.984375rem;
  display: flex;
  box-sizing: border-box;
}

.home-container .board .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-container .board .title .txt {
  font-size: 0.109375rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #595757;
  line-height: 0.177083rem;
}

.home-container .board .title .more {
  background: #FFFFFF;
  border: 0.005208rem solid #DCDDDD;
  padding: 0 0.020833rem;
  height: 0.096875rem;
  line-height: 0.096875rem;
  color: #a1a1a1;
  text-decoration: none;
  font-size: 0.078125rem;
}

.home-container .board .news {
  width: -moz-fit-content;
  width: fit-content;
}

.home-container .board .news .news-content {
  display: flex;
  margin-top: 0.171875rem;
}

.home-container .board .news .news-content .news-block {
  width: 1.911458rem;
  position: relative;
}

.home-container .board .news .news-content .news-block:hover {
  top: -0.052083rem;
}

.home-container .board .news .news-content .news-block:nth-child(n+2) {
  margin-left: 0.125rem;
}

.home-container .board .news .news-content .news-block .img {
  width: 1.911458rem;
  height: 1.114583rem;
  border-radius: 0.052083rem;
  overflow: hidden;
  background: #000;
  margin-bottom: 0.140625rem;
}

.home-container .board .news .news-content .news-block .img>img {
  width: 100%;
  height: auto;
}

.home-container .board .news .news-content .news-block .news-title {
  width: 1.854167rem;
  font-size: 0.088542rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #595757;
  line-height: 0.151042rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.0625rem;
}

.home-container .board .news .news-content .news-block .news-title span {
  display: inline-block;
  color: #EEC31C;
}