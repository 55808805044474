@font-face {
  font-family: Bebas;
  src: url("../../../../font/BEBAS.ttf");
}

@font-face {
  font-family: Noto;
  src: url("../../../../font/noto-sans.woff.ttf");
}

body,
ul {
  margin: 0;
  padding: 0;
}

html {
  min-width: 5.208333rem;
}

html,
button,
input,
select,
textarea {
  font-family: "Hiragino Sans GB","Helvetica Neue",Helvetica,Tahoma,"Microsoft Yahei",sans-serif;
}

a {
  white-space: nowrap;
  display: block;
  text-align: center;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
}

#root {
  position: relative;
}

@media not screen and (min-width: 769px) and (max-width: 992px) {
  main {
    width: 4.166667rem;
  }

  .introductionPage .content {
    width: 4.166667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.052083rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  main {
    width: 4.791667rem;
  }

  .introductionPage .content {
    width: 4.791667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    width: 2.109375rem;
    height: 1.1875rem;
  }

  .video-title {
    max-width: 2.109375rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  main {
    width: 5.208333rem;
  }

  .introductionPage .content {
    width: 5.208333rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    height: 1.302083rem;
    width: 2.317708rem;
  }

  .video-title {
    max-width: 2.317708rem;
  }
}

@media only screen and (min-width: 1600px) {
  main {
    width: 6.25rem;
  }

  .introductionPage .content {
    width: 6.25rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.28125rem;
  }

  .video-poster {
    height: 1.567708rem;
    width: 2.78125rem;
  }

  .video-title {
    max-width: 2.78125rem;
  }
}

.video-block2 {
  margin-bottom: 0.145833rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.mask-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 100;
  width: 100vw;
  height: 100vh;
}

.mask-container .video-player {
  width: 4.166667rem;
  height: 2.604167rem;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
}

.mask-container .video-mask {
  position: fixed;
  background: black;
  opacity: 0.8;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 100;
  width: 100vw;
  height: 100vh;
}