.live-page {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  padding: 0.322917rem 1.734375rem 0.385417rem;
}

.viewer {
  width: 0.708333rem;
  height: 0.166667rem;
  background: linear-gradient(90deg, #000 0%, rgba(0,0,0,0) 100%);
  opacity: 0.4;
  border-radius: 0.416667rem;
}

.viewer-cont {
  position: relative;
  padding: 0.026042rem;
  margin-top: -0.15625rem;
  margin-left: 0.020833rem;
  z-index: 999;
}

.viewer-cont span {
  position: absolute;
  white-space: nowrap;
  margin-left: 0.026042rem;
  font-size: 0.078125rem;
  margin-top: -0.015625rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #fff;
}

.viewer-eye {
  width: 0.130208rem;
}

.live-bg {
  position: absolute;
  top: 0;
  width: 100%;
}

.player-container {
  resize: horizontal;
  position: relative;
}

.player-section {
  position: relative;
  height: 3.645833rem;
}

.game-section {
  position: relative;
  padding: 0.322917rem 0.96875rem 0.385417rem;
}

.game-section .title {
  position: relative;
  bottom: 0.208333rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.09375rem;
  line-height: 0.15625rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  box-sizing: border-box;
  color: #595757;
}

.player-col-even {
  background-color: #fff;
  padding-top: 0.067708rem;
  padding-bottom: 0.067708rem;
  padding-left: 0.208333rem;
}

.player-col-odd {
  background-color: #fafafa;
  padding-top: 0.067708rem;
  padding-bottom: 0.067708rem;
  padding-left: 0.208333rem;
}

.player-card {
  margin-top: 0.104167rem;
}

.player-card .player-header {
  width: 0.364583rem;
  height: 0.364583rem;
  border: 0.020833rem solid #e6e6e6;
  border-radius: 50%;
}

.player-card .player-info {
  position: relative;
  top: 0.052083rem;
  margin-left: 0.104167rem;
  font-size: 0.078125rem;
  line-height: 0.15625rem;
  font-family: Hiragino Sans GB;
  color: #595757;
}

.player-card .player-name {
  margin-left: 0.015625rem;
  font-size: 0.09375rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #595757;
}

.player-card .player-title {
  margin-left: 0.015625rem;
  font-size: 0.09375rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #b5b5b6;
}