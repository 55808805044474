.firstClassNav {
  width: 100%;
  height: 0.65625rem;
  background-color: #F8CA00;
  display: flex;
  position: relative;
  z-index: 10000;
  top: 0;
}

.firstClassNav .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.firstClassNav .nav img {
  width: auto;
  height: 0.21875rem;
  margin-top: 0.109375rem;
}

.firstClassNav .nav ul {
  height: 0.46875rem;
  margin: auto;
  display: flex;
  justify-content: center;
  min-width: 3.125rem;
  font-size: 0.072917rem;
}

.firstClassNav .nav ul .home-link {
  margin-right: 1.041667rem;
}

.firstClassNav .nav ul .link-item {
  box-sizing: border-box;
  margin-right: 0.364583rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.firstClassNav .nav ul .link-item a {
  white-space: nowrap;
  display: block;
  text-align: center;
  font-size: 0.119792rem;
  height: 0.46875rem;
  line-height: 0.46875rem;
  font-weight: 400;
  font-family: Bebas, Roboto-Black,Roboto;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
  color: #333333;
}

.firstClassNav .nav ul .link-item-active a {
  color: #fff;
}

.lang {
  cursor: pointer;
  font-family: Bebas, Roboto-Black,Roboto;
  right: 0.484375rem;
  top: 50%;
  transform: translateY(-50%);
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  position: absolute;
  padding-top: 0.005208rem;
}

.lang>span {
  display: inline-block;
  height: 0.109375rem;
  font-size: 0.072917rem;
  padding: 0 0.036458rem;
  vertical-align: middle;
}

.lang .en {
  font-weight: 400;
  color: #F8CA00;
  background: #fff;
  line-height: 0.109375rem;
}

.lang .ch {
  border: 0.005208rem solid #131314;
  box-sizing: border-box;
  color: #131314;
  line-height: 0.09375rem;
}