.swiper-item {
  width: 100%;
}

.swiper-item img {
  cursor: pointer;
  width: 100%;
}

.swiper-button-next {
  background-size: 100%;
  right: 18%;
  width: 0.260417rem;
  height: 0.416667rem;
  color: #C3C2C2;
}

.swiper-button-prev {
  background-size: 100%;
  left: 18%;
  width: 0.260417rem;
  height: 0.416667rem;
  color: #C3C2C2;
}

.swiper-pagination {
  bottom: 0.114583rem !important;
}

.swiper-pagination-bullet {
  background: #C3C2C2;
}

.swiper-pagination-bullet-active {
  background: #2EA7E0;
}