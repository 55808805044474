.video-list-container {
  flex-wrap: wrap;
  display: inline-flex;
  grid-gap: 0.104167rem;
  gap: 0.104167rem;
  margin-top: 0.104167rem;
  margin-bottom: 0.104167rem;
}

.video-list-container .video-item {
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.video-list-container .video-item .cover-container {
  display: block;
  align-items: center;
  height: 0.84375rem;
  width: 100%;
  justify-content: center;
}

.video-list-container .video-item .cover-container .img-play {
  position: relative;
  margin-left: 0.625rem;
  left: 0;
  top: -0.520833rem;
  width: 0.260417rem;
  height: 0.260417rem;
}

.video-list-container .video-item .cover-container .shadow {
  position: relative;
  left: 0;
  top: 0;
  width: 1.510417rem;
  height: 0.416667rem;
  box-shadow: 0 0.088542rem 0.052083rem -0.041667rem #b6b6b6 inset;
}

.video-list-container .video-item .cover-container .cover {
  position: relative;
  top: 0;
  left: 0;
  height: 0.84375rem;
  width: 100%;
  border-radius: 0.026042rem;
}

.video-list-container .video-item .title {
  position: relative;
  width: 1.510417rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.09375rem;
  line-height: 0.15625rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  box-sizing: border-box;
  color: #3e3a39;
}

.video-list-container .video-item .info {
  display: flex;
  color: #aaa;
  font-size: 0.083333rem;
  align-items: center;
  justify-content: space-between;
}

.video-list-container .video-item .info .left {
  display: flex;
}

.video-list-container .video-item .info .left img {
  width: 0.125rem;
  height: 0.125rem;
}

.video-list-container .video-item .info .left .champion {
  margin-top: 0.010417rem;
}

.video-list-container .video-item .info .right {
  display: flex;
  justify-content: flex-end;
}

.video-item {
  width: 1.510417rem;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 0.104167rem;
}

.video-item .cover-container {
  display: block;
  align-items: center;
  height: 0.84375rem;
  justify-content: center;
}

.video-item .cover-container .img-play {
  position: relative;
  margin-left: 0.625rem;
  left: 0;
  top: -0.520833rem;
  width: 0.260417rem;
  height: 0.260417rem;
}

.video-item .cover-container .shadow {
  position: relative;
  left: 0;
  top: 0;
  width: 1.510417rem;
  height: 0.416667rem;
  box-shadow: 0 0.088542rem 0.052083rem -0.041667rem #b6b6b6 inset;
}

.video-item .cover-container .cover {
  position: relative;
  top: 0;
  left: 0;
  height: 0.84375rem;
  width: 1.510417rem;
  border-radius: 0.026042rem;
}

.video-item .title {
  position: relative;
  width: 1.510417rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.09375rem;
  line-height: 0.15625rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  box-sizing: border-box;
  color: #3e3a39;
}

.video-item .info {
  display: flex;
  color: #aaa;
  font-size: 0.083333rem;
  align-items: center;
  justify-content: space-between;
}

.video-item .info .left {
  display: flex;
}

.video-item .info .left img {
  width: 0.125rem;
  height: 0.125rem;
}

.video-item .info .left .champion {
  margin-top: 0.010417rem;
}

.video-item .info .right {
  display: flex;
  justify-content: flex-end;
}

.search-tool-bar {
  display: flex;
  display: -webkit-flex;
  margin-bottom: 0.104167rem;
  margin-left: 0.104167rem;
}

.video-page {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  padding: 0.322917rem 1.734375rem 0.385417rem;
}

.page-nav {
  display: flex;
  display: -webkit-flex;
}

.footer-more {
  background: #e6e6e6;
  height: 0.546875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-more .more-btn {
  cursor: pointer;
  display: block;
  border: none;
  padding: 0 0.104167rem;
  height: 0.229167rem;
  text-align: center;
  line-height: 0.229167rem;
  font-size: 0.09375rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #ffffff;
  background: #eec31c;
  border-radius: 0.026042rem;
}

#DIV_1 {
  align-items: stretch;
  block-size: 0.197917rem;
  border-block-end-color: #212529;
  border-block-start-color: #212529;
  border-inline-end-color: #212529;
  border-inline-start-color: #212529;
  bottom: 0;
  box-sizing: border-box;
  caret-color: #212529;
  color: #212529;
  column-rule-color: #212529;
  display: flex;
  height: 0.197917rem;
  inline-size: 4.533802rem;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  left: 0;
  margin-block-end: 0.083333rem;
  perspective-origin: 2.266875rem 0.098958rem;
  position: relative;
  right: 0;
  text-align: left;
  -webkit-text-decoration: none solid #212529;
  text-decoration: none solid #212529;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  top: 0;
  transform-origin: 2.266901rem 0.098958rem;
  width: 4.533802rem;
  border: 0 none #212529;
  flex-flow: row wrap;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0 0 0.083333rem;
  outline: #212529 none 0;
}

#DIV_1:after {
  border-block-end-color: #212529;
  border-block-start-color: #212529;
  border-inline-end-color: #212529;
  border-inline-start-color: #212529;
  box-sizing: border-box;
  caret-color: #212529;
  color: #212529;
  column-rule-color: #212529;
  display: block;
  text-align: left;
  -webkit-text-decoration: none solid #212529;
  text-decoration: none solid #212529;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  border: 0 none #212529;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: #212529 none 0;
}

#DIV_1:before {
  border-block-end-color: #212529;
  border-block-start-color: #212529;
  border-inline-end-color: #212529;
  border-inline-start-color: #212529;
  box-sizing: border-box;
  caret-color: #212529;
  color: #212529;
  column-rule-color: #212529;
  display: block;
  text-align: left;
  -webkit-text-decoration: none solid #212529;
  text-decoration: none solid #212529;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  border: 0 none #212529;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: #212529 none 0;
}

#INPUT_2 {
  block-size: 0.197917rem;
  border-block-end-color: #ced4da;
  border-block-end-style: solid;
  border-block-end-width: 0.005208rem;
  border-block-start-color: #ced4da;
  border-block-start-style: solid;
  border-block-start-width: 0.005208rem;
  border-end-end-radius: 0;
  border-end-start-radius: 0.020833rem;
  border-inline-end-color: #ced4da;
  border-inline-end-style: solid;
  border-inline-end-width: 0.005208rem;
  border-inline-start-color: #ced4da;
  border-inline-start-style: solid;
  border-inline-start-width: 0.005208rem;
  border-start-end-radius: 0;
  border-start-start-radius: 0.020833rem;
  bottom: 0;
  caret-color: #495057;
  color: #495057;
  column-rule-color: #495057;
  display: block;
  height: 0.197917rem;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  left: 0;
  min-block-size: auto;
  min-height: auto;
  padding-block-end: 0.03125rem;
  padding-block-start: 0.03125rem;
  padding-inline-end: 0.0625rem;
  padding-inline-start: 0.0625rem;
  perspective-origin: 1.919rem 0.098958rem;
  position: relative;
  right: 0;
  -webkit-text-decoration: none solid #495057;
  text-decoration: none solid #495057;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  top: 0;
  transform-origin: 1.919026rem 0.098958rem;
  width: 1.5625rem;
  background: #fff none repeat scroll 0% 0%/auto padding-box padding-box;
  border: 0.005208rem solid #ced4da;
  border-radius: 0.020833rem 0 0 0.020833rem;
  flex: 1 1 auto;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: #495057 none 0;
  padding: 0.03125rem 0.0625rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

#INPUT_2:before {
  border-block-end-color: #495057;
  border-block-start-color: #495057;
  border-inline-end-color: #495057;
  border-inline-start-color: #495057;
  box-sizing: border-box;
  caret-color: #495057;
  color: #495057;
  column-rule-color: #495057;
  -webkit-text-decoration: none solid #495057;
  text-decoration: none solid #495057;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  border: 0 none #495057;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: #495057 none 0;
}

#DIV_3 {
  block-size: 0.197917rem;
  border-block-end-color: #212529;
  border-block-start-color: #212529;
  border-inline-end-color: #212529;
  border-inline-start-color: #212529;
  box-sizing: border-box;
  caret-color: #212529;
  color: #212529;
  column-rule-color: #212529;
  display: flex;
  height: 0.197917rem;
  inline-size: 0.697917rem;
  margin-inline-start: -0.005208rem;
  cursor: pointer;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  perspective-origin: 0.350478rem 0.098958rem;
  text-align: left;
  -webkit-text-decoration: none solid #212529;
  text-decoration: none solid #212529;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  transform-origin: 0.350478rem 0.098958rem;
  width: 0.572917rem;
  border: 0 none #212529;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0 0 0 -0.005208rem;
  outline: #212529 none 0;
}

#DIV_3:after {
  border-block-end-color: #212529;
  border-block-start-color: #212529;
  border-inline-end-color: #212529;
  border-inline-start-color: #212529;
  box-sizing: border-box;
  caret-color: #212529;
  color: #212529;
  column-rule-color: #212529;
  display: block;
  text-align: left;
  -webkit-text-decoration: none solid #212529;
  text-decoration: none solid #212529;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  border: 0 none #212529;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: #212529 none 0;
}

#DIV_3:before {
  border-block-end-color: #212529;
  border-block-start-color: #212529;
  border-inline-end-color: #212529;
  border-inline-start-color: #212529;
  box-sizing: border-box;
  caret-color: #212529;
  color: #212529;
  column-rule-color: #212529;
  display: block;
  text-align: left;
  -webkit-text-decoration: none solid #212529;
  text-decoration: none solid #212529;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  border: 0 none #212529;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: #212529 none 0;
}

.search-icon {
  color: #495057;
  width: 0.114583rem;
  height: 0.114583rem;
  margin-right: 0.020833rem;
}

#SPAN_4 {
  align-items: center;
  border-block-end-color: #ced4da;
  border-block-end-style: solid;
  border-block-end-width: 0.005208rem;
  border-block-start-color: #ced4da;
  border-block-start-style: solid;
  border-block-start-width: 0.005208rem;
  border-end-end-radius: 0.020833rem;
  border-inline-end-color: #ced4da;
  border-inline-end-style: solid;
  border-inline-end-width: 0.005208rem;
  border-inline-start-color: #ced4da;
  border-inline-start-style: solid;
  border-inline-start-width: 0.005208rem;
  border-start-end-radius: 0.020833rem;
  box-sizing: border-box;
  caret-color: #495057;
  color: #495057;
  column-rule-color: #495057;
  display: flex;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  padding-block-end: 0.03125rem;
  padding-block-start: 0.03125rem;
  padding-inline-end: 0.0625rem;
  padding-inline-start: 0.0625rem;
  perspective-origin: 0.350478rem 0.098958rem;
  text-align: center;
  -webkit-text-decoration: none solid #495057;
  text-decoration: none solid #495057;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  transform-origin: 0.350478rem 0.098958rem;
  white-space: nowrap;
  width: 0.700953rem;
  background: #e9ecef none repeat scroll 0% 0%/auto padding-box border-box;
  border: 0.005208rem solid #ced4da;
  border-radius: 0 0.020833rem 0.020833rem 0;
  font-weight: bold;
  outline: #495057 none 0;
  padding: 0.03125rem 0.0625rem;
}

#SPAN_4:after {
  border-block-end-color: #495057;
  border-block-start-color: #495057;
  border-inline-end-color: #495057;
  border-inline-start-color: #495057;
  box-sizing: border-box;
  caret-color: #495057;
  color: #495057;
  column-rule-color: #495057;
  display: block;
  text-align: center;
  -webkit-text-decoration: none solid #495057;
  text-decoration: none solid #495057;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  white-space: nowrap;
  border: 0 none #495057;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: #495057 none 0;
}

#SPAN_4:before {
  border-block-end-color: #495057;
  border-block-start-color: #495057;
  border-inline-end-color: #495057;
  border-inline-start-color: #495057;
  box-sizing: border-box;
  caret-color: #495057;
  color: #495057;
  column-rule-color: #495057;
  display: block;
  text-align: center;
  -webkit-text-decoration: none solid #495057;
  text-decoration: none solid #495057;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  white-space: nowrap;
  border: 0 none #495057;
  font: 0.083333rem / 0.125rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  outline: #495057 none 0;
}