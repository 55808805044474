@font-face {
  font-family: Bebas;
  src: url("../../font/BEBAS.ttf");
}

@font-face {
  font-family: Noto;
  src: url("../../font/noto-sans.woff.ttf");
}

body,
ul {
  margin: 0;
  padding: 0;
}

html {
  min-width: 5.208333rem;
}

html,
button,
input,
select,
textarea {
  font-family: "Hiragino Sans GB","Helvetica Neue",Helvetica,Tahoma,"Microsoft Yahei",sans-serif;
}

a {
  white-space: nowrap;
  display: block;
  text-align: center;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
}

#root {
  position: relative;
}

@media not screen and (min-width: 769px) and (max-width: 992px) {
  main {
    width: 4.166667rem;
  }

  .introductionPage .content {
    width: 4.166667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.052083rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  main {
    width: 4.791667rem;
  }

  .introductionPage .content {
    width: 4.791667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    width: 2.109375rem;
    height: 1.1875rem;
  }

  .video-title {
    max-width: 2.109375rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  main {
    width: 5.208333rem;
  }

  .introductionPage .content {
    width: 5.208333rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    height: 1.302083rem;
    width: 2.317708rem;
  }

  .video-title {
    max-width: 2.317708rem;
  }
}

@media only screen and (min-width: 1600px) {
  main {
    width: 6.25rem;
  }

  .introductionPage .content {
    width: 6.25rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.28125rem;
  }

  .video-poster {
    height: 1.567708rem;
    width: 2.78125rem;
  }

  .video-title {
    max-width: 2.78125rem;
  }
}

.introductionPage {
  background: url("../../public/img/bg.jpg") no-repeat;
  background-size: cover;
}

.introductionPage .banner {
  position: relative;
  font-family: Noto;
}

.introductionPage .banner>img {
  width: 100%;
}

.introductionPage .banner .content {
  position: absolute;
  padding-top: 0.005208rem;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

.introductionPage .banner .content>img {
  width: 100%;
  position: relative;
  left: 0.140625rem;
}

.introductionPage .banner .content p {
  font-size: 0.09375rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #fefefe;
  line-height: 0.125rem;
  padding-left: 0.104167rem;
}

.introductionPage main {
  margin: 1.078125rem auto;
}

.introductionPage main>img {
  width: 100%;
}

.introductionPage main .card-1 {
  position: relative;
}

.introductionPage main .card-1 .card {
  width: 100%;
  position: relative;
  left: -0.234375rem;
}

.introductionPage main .card-1 .player {
  position: absolute;
  height: 3.640625rem;
  bottom: 0;
  right: -0.15625rem;
}

.introductionPage main .brands {
  padding: 0 0.15625rem;
}

.introductionPage main .brands>img {
  width: 100%;
}