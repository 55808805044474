.header-container {
  box-sizing: border-box;
}

.header-container .active-tab {
  font-weight: 900;
  font-size: 0.088542rem !important;
  color: #000 !important;
}

.header-container .top-header {
  height: 0.177083rem;
  width: 100%;
  background: #fafafa;
  border-bottom: #e6e6e6 0.005208rem solid;
  font-size: 0.057292rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #898989;
  line-height: 0.177083rem;
  padding-left: 1.015625rem;
  box-sizing: border-box;
}

.header-container .nav2 {
  height: 0.385417rem;
  padding-left: 0.953125rem;
  display: flex;
  align-items: center;
}

.header-container .nav2 .logo2 {
  width: 0.770833rem;
  height: 0.21875rem;
}

.header-container .nav2 .cols {
  line-height: 0.385417rem;
  font-size: 0.083333rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  padding: 0;
}

.header-container .nav2 .cols li,
.header-container .nav2 .cols a {
  display: inline-block;
  padding: 0 0.09375rem;
  list-style-type: none;
  position: relative;
  text-decoration: none;
  color: #595757;
}

.header-container .nav2 .cols li .icon,
.header-container .nav2 .cols a .icon {
  position: absolute;
  width: 0.15625rem;
  height: 0.130208rem;
  background: red;
  top: 0.052083rem;
  right: -0.078125rem;
  transform: scale(0.9);
}

.header-container .nav2 .cols li .hot,
.header-container .nav2 .cols a .hot {
  background: url("./img/hot.svg");
  background-size: 100% 100%;
}

.header-container .nav2 .cols li .shop,
.header-container .nav2 .cols a .shop {
  background: url("./img/shop.svg");
  background-size: 100% 100%;
}

.red-icon {
  content: " ";
  border: 0.020833rem solid red;
  border-radius: 0.020833rem;
  position: absolute;
  z-index: 1000;
  right: 0.026042rem;
  top: 0.104167rem;
}

.cols {
  line-height: 0.385417rem;
  font-size: 0.083333rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  padding: 0;
}

.cols li,
.cols a {
  display: inline-block;
  padding: 0 0.09375rem;
  list-style-type: none;
  position: relative;
  text-decoration: none;
  color: #595757;
}

.cols li .icon,
.cols a .icon {
  position: absolute;
  width: 0.15625rem;
  height: 0.130208rem;
  background: red;
  top: 0.052083rem;
  right: -0.078125rem;
  transform: scale(0.9);
}

.cols li .hot,
.cols a .hot {
  background: url("./img/hot.svg");
  background-size: 100% 100%;
}

.cols li .shop,
.cols a .shop {
  background: url("./img/shop.svg");
  background-size: 100% 100%;
}

.login-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.083333rem;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0.114583rem;
  color: #333333;
  position: absolute;
  right: 0.520833rem;
  height: 0.385417rem;
  width: 0.78125rem;
}

.login-btns .login-btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.login-btns .sep {
  width: 0.010417rem;
  height: 0.083333rem;
  background: url("./img/sep.png") no-repeat;
  background-size: 100% 100%;
}

.login-modal-header {
  font-size: 0.0625rem;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0.088542rem;
  color: #999999;
  text-align: center;
  margin-bottom: 0.15625rem;
}

.login-form {
  width: 100%;
}

.ant-input {
  width: 100%;
}

.code-img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.submit-btn {
  width: 100% !important;
  height: 0.260417rem !important;
}

.ant-input {
  height: 0.260417rem;
}

.user-info {
  width: auto;
  justify-content: flex-start;
}

.user-info .avatar {
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 5.203125rem;
  margin-right: 0.083333rem;
}

.user-info .user-name {
  min-width: 0.416667rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  cursor: auto;
}