.tail {
  width: 100%;
  height: auto;
  background: #F8CA00;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.546875rem;
  padding: 0.447917rem 0 0.416667rem;
  font-family: Bebas,Roboto-Regular,Roboto;
}

.tail .copy-contain {
  color: #131314;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tail .copy-contain .navs {
  margin-bottom: 0.4375rem;
}

.tail .copy-contain .navs .nav {
  font-size: 0.125rem;
  line-height: 0.125rem;
  padding: 0 0.182292rem;
  display: inline-block;
  color: #333333;
}

.tail .copy-contain .navs .nav:nth-child(n+2) {
  border-left: 0.010417rem solid #131314;
}

.tail .copy-contain .navs .active {
  color: #fff !important;
}

.tail .copy-contain .link-list {
  cursor: pointer;
  display: flex;
  width: 1.458333rem;
  justify-content: space-around;
}

.tail .copy-contain .link-list>.icon {
  width: 0.15625rem;
  height: 0.15625rem;
  overflow: hidden;
  background-position: 0 100% !important;
  background-size: 100% 200% !important;
}

.tail .copy-contain .link-list>.icon:hover {
  background-position: 0 0% !important;
}

.tail .copy-contain .link-list .ins {
  background: url("./icon/ins.png") no-repeat;
}

.tail .copy-contain .link-list .youtube {
  background: url("./icon/youtube.png") no-repeat;
}

.tail .copy-contain .link-list .douyin {
  background: url("./icon/douyin.png") no-repeat;
}

.tail .copy-contain .link-list .tw {
  background: url("./icon/tw.png") no-repeat;
}

.tail .copy-contain .link-list .facebook {
  background: url("./icon/facebook.png") no-repeat;
}

.tail .copy-contain .copy-text {
  height: 0.083333rem;
  margin: 0.151042rem 0 0;
}

.tail .copy-contain .copy-text>img {
  margin: 0;
  height: 100%;
  width: auto;
}

.tail .copy-contain .logo {
  width: 0.755208rem;
  height: auto;
  margin-top: 0.520833rem;
}