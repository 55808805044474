.game-page {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  background-color: #f6f6f6;
  padding: 0.322917rem 1.734375rem 0.385417rem;
}

.game-card {
  height: "0.546875rem";
}

.flexbox {
  width: 0.260417rem;
  height: 0.260417rem;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.area-card {
  width: 100%;
  height: 0.265625rem;
  background-color: #ffffff;
  border-radius: 0.026042rem;
  box-shadow: 0 0.026042rem 0.026042rem #ddd;
}

.area-card-top {
  width: 100%;
  background-color: #eec31c;
  height: 0.265625rem;
}

.icon-live {
  width: 0.197917rem;
  height: 0.09375rem;
  background: #eec31c;
  border-radius: 0.026042rem;
}

.area-top2 {
  width: 100%;
  height: 0.260417rem;
  background-color: #eec31c;
  border-radius: 0.026042rem;
  height: 0.364583rem;
  margin-top: 0.104167rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.area-top2 .title {
  margin-left: 0.104167rem;
  font-size: 0.09375rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #595757;
}

.area-top2 .right {
  position: absolute;
}

.area-top2 .right .btn-more {
  cursor: pointer;
  display: block;
  border: none;
  height: 0.229167rem;
  text-align: center;
  line-height: 0.229167rem;
  width: 0.78125rem;
  font-size: 0.09375rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #191c22;
  background: #fff;
  border-radius: 0.026042rem;
}

.line_01 {
  float: left;
  position: relative;
  margin: 0.041667rem;
  width: 0.005208rem;
  height: 0.182292rem;
  background: #e6e6e6;
}

.game-end .right {
  display: relative;
}

.game-end .left {
  display: flex;
  align-items: center;
  height: 0.229167rem;
}

.game-end img {
  margin-top: 0.057292rem;
  width: 0.125rem;
}

.game-end .video-img {
  cursor: pointer;
  margin-top: 0.067708rem;
  width: 0.125rem;
}

.game-end .title {
  margin-top: 0.067708rem;
  font-size: 0.078125rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #b5b5b6;
}

.tab {
  font-size: 0.09375rem;
  text-align: center;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #b5b5b6;
}

.player-title {
  font-size: 0.09375rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #b5b5b6;
}

.gray-title {
  margin-top: 0.067708rem;
  font-size: 0.078125rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #b5b5b6;
}

.video-title {
  cursor: pointer;
  margin-top: 0.067708rem;
  font-size: 0.078125rem;
  font-family: HiraginoSansGB-W3 Hiragino Sans GB;
  color: #b5b5b6;
}

.area-title {
  margin-top: -0.020833rem;
  font-size: 0.078125rem;
  color: #595757;
  line-height: 0.3125rem;
  font-weight: 500;
}

.yellow-title {
  font-size: 0.078125rem;
  color: #eec31c;
  line-height: 0.3125rem;
}