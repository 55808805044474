.news-container {
  width: 100%;
}

.news-container .news {
  border-top: 0.005208rem solid #e6e6e6;
  padding: 0.3125rem 2.651042rem;
  box-sizing: border-box;
}

.news-container .news .indexs {
  font-size: 0.078125rem;
  line-height: 0.078125rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
}

.news-container .news .indexs a,
.news-container .news .indexs span {
  display: inline-block;
  margin: 0.026042rem;
  color: #9AA2AA;
  text-decoration: none;
}

.news-container .news .news-title {
  font-size: 0.1875rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #000000;
  line-height: 0.260417rem;
  margin-top: 0.3125rem;
}

.news-container .news .news-info {
  display: flex;
  margin-top: 0.130208rem;
}

.news-container .news .news-info .author {
  font-size: 0.078125rem;
  line-height: 0.078125rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #9AA2AA;
  margin-right: 0.125rem;
}

.news-container .news .news-info .time {
  font-size: 0.078125rem;
  line-height: 0.078125rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #9AA2AA;
}

.news-container .news .news-content {
  margin-top: 0.3125rem;
  font-size: 0.078125rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #595757;
}

.news-container .news .news-content img {
  border-radius: 0.052083rem;
  max-width: 100%;
}