.about-container .banner {
  width: 100%;
  height: auto;
}

.about-container .content {
  padding: 0.296875rem 2.651042rem;
  box-sizing: border-box;
  background: #F7F8F8;
}

.about-container .content .des {
  font-size: 0.083333rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #595757;
  line-height: 0.15625rem;
}

.about-container .content .des .row:nth-child(n+2) {
  margin-top: 0.104167rem;
}

.about-container .content .des .padding-left-30 {
  padding-left: 0.15625rem;
}

.about-container .content .title {
  font-size: 0.1875rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #000000;
  line-height: 0.125rem;
  margin: 0.3125rem 0;
}

.about-container .content .detail {
  font-size: 0.078125rem;
  font-family: Hiragino Sans GB;
  font-weight: normal;
  color: #595757;
  line-height: 0.15625rem;
}

.about-container .content .partner {
  width: 100%;
  height: auto;
}

.about-container .content ul li {
  list-style-type: disc;
  margin-left: 0.104167rem;
}