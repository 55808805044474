@font-face {
  font-family: Bebas;
  src: url("../../font/BEBAS.ttf");
}

@font-face {
  font-family: Noto;
  src: url("../../font/noto-sans.woff.ttf");
}

body,
ul {
  margin: 0;
  padding: 0;
}

html {
  min-width: 5.208333rem;
}

html,
button,
input,
select,
textarea {
  font-family: "Hiragino Sans GB","Helvetica Neue",Helvetica,Tahoma,"Microsoft Yahei",sans-serif;
}

a {
  white-space: nowrap;
  display: block;
  text-align: center;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
}

#root {
  position: relative;
}

@media not screen and (min-width: 769px) and (max-width: 992px) {
  main {
    width: 4.166667rem;
  }

  .introductionPage .content {
    width: 4.166667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.052083rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  main {
    width: 4.791667rem;
  }

  .introductionPage .content {
    width: 4.791667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    width: 2.109375rem;
    height: 1.1875rem;
  }

  .video-title {
    max-width: 2.109375rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  main {
    width: 5.208333rem;
  }

  .introductionPage .content {
    width: 5.208333rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    height: 1.302083rem;
    width: 2.317708rem;
  }

  .video-title {
    max-width: 2.317708rem;
  }
}

@media only screen and (min-width: 1600px) {
  main {
    width: 6.25rem;
  }

  .introductionPage .content {
    width: 6.25rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.28125rem;
  }

  .video-poster {
    height: 1.567708rem;
    width: 2.78125rem;
  }

  .video-title {
    max-width: 2.78125rem;
  }
}

.reviewPage {
  background: url("../../public/img/bg.jpg") no-repeat;
  background-size: cover;
}

.reviewPage main {
  margin: 1.0625rem auto;
  font-family: Noto;
}

.reviewPage main img {
  width: 100%;
  display: inline-block;
  margin-bottom: 0.28125rem;
}

.reviewPage main .des {
  margin-bottom: 0.255208rem;
  font-size: 0.109375rem;
  font-family: Noto;
  font-weight: 400;
  color: #373739;
  line-height: 0.135417rem;
}