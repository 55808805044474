@font-face {
  font-family: Bebas;
  src: url("../../font/BEBAS.ttf");
}

@font-face {
  font-family: Noto;
  src: url("../../font/noto-sans.woff.ttf");
}

body,
ul {
  margin: 0;
  padding: 0;
}

html {
  min-width: 5.208333rem;
}

html,
button,
input,
select,
textarea {
  font-family: "Hiragino Sans GB","Helvetica Neue",Helvetica,Tahoma,"Microsoft Yahei",sans-serif;
}

a {
  white-space: nowrap;
  display: block;
  text-align: center;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
}

#root {
  position: relative;
}

@media not screen and (min-width: 769px) and (max-width: 992px) {
  main {
    width: 4.166667rem;
  }

  .introductionPage .content {
    width: 4.166667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.052083rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  main {
    width: 4.791667rem;
  }

  .introductionPage .content {
    width: 4.791667rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    width: 2.109375rem;
    height: 1.1875rem;
  }

  .video-title {
    max-width: 2.109375rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  main {
    width: 5.208333rem;
  }

  .introductionPage .content {
    width: 5.208333rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.15625rem;
  }

  .video-poster {
    height: 1.302083rem;
    width: 2.317708rem;
  }

  .video-title {
    max-width: 2.317708rem;
  }
}

@media only screen and (min-width: 1600px) {
  main {
    width: 6.25rem;
  }

  .introductionPage .content {
    width: 6.25rem;
  }

  .introductionPage .banner .content>img {
    margin-top: 0.28125rem;
  }

  .video-poster {
    height: 1.567708rem;
    width: 2.78125rem;
  }

  .video-title {
    max-width: 2.78125rem;
  }
}

.contactPage {
  background: url("../../public/img/bg.jpg") no-repeat;
  background-size: cover;
}

.contactPage main {
  margin: 1.041667rem auto 0.703125rem;
  position: relative;
}

.contactPage main .card {
  position: relative;
  left: -0.520833rem;
}

.contactPage main .card>img {
  width: 5.96875rem;
}

.contactPage main .card .player {
  width: 2.130208rem;
  height: 3.484375rem;
  position: absolute;
  bottom: 0.130208rem;
  right: -0.416667rem;
}

.contactPage main .card .info {
  position: absolute;
  left: 0.6875rem;
  top: 0.859375rem;
}

.contactPage main .card .info .email {
  display: flex;
  align-items: center;
}

.contactPage main .card .info .email>div {
  display: inline-block;
}

.contactPage main .card .info .email .icon {
  width: 0.260417rem;
  text-align: center;
}

.contactPage main .card .info .email .icon img {
  width: 0.239583rem;
  height: auto;
}

.contactPage main .card .info .email .title {
  font-size: 0.1875rem;
  font-family: Bebas;
  font-weight: 400;
  color: #373739;
  width: 0.9375rem;
  margin-left: 0.104167rem;
}

.contactPage main .card .info .email .detail {
  font-size: 0.166667rem;
  font-family: Bebas;
  font-weight: 400;
  color: #5f5f60;
  margin-left: 0.208333rem;
}

.contactPage main .card .info .telephone {
  margin-top: 0.322917rem;
  display: flex;
}

.contactPage main .card .info .telephone>div {
  display: inline-block;
}

.contactPage main .card .info .telephone .icon {
  width: 0.260417rem;
  text-align: center;
}

.contactPage main .card .info .telephone .icon img {
  width: 0.1875rem;
  height: auto;
}

.contactPage main .card .info .telephone .title {
  font-size: 0.1875rem;
  font-family: Bebas;
  font-weight: 400;
  color: #373739;
  width: 0.9375rem;
  margin-left: 0.104167rem;
}

.contactPage main .card .info .telephone .detail {
  font-size: 0.166667rem;
  font-family: Bebas;
  font-weight: 400;
  color: #5f5f60;
  margin-left: 0.208333rem;
}